import React, { useState, useEffect } from "react";
import Login from "../components/login";

export default function LoginPage() {

    return (
        <>
        <Login/>
        </>
    )
}