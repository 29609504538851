export default function Footer() {
    return (
      <div className="w-full bg-green-700 text-gray-400 mt-auto">
        <div className="flex justify-center gap-1 p-2 text-center text-sm text-white">
          <span>UMarketplace - AIEP 2024</span>
          <span className="font-semibold text-gray-800"> by: Kolbriu, Arshock, Prakon</span>
        </div>
      </div>
    );
  }
  